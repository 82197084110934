import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/seo"
import BannerWithText from "../components/atoms/BannerWithText"
import HeaderText from "../components/atoms/HeaderText"
import WysiwygContainer from "../components/atoms/WysiwygContainer"

const BiometricInformationPrivacyPolicy = data => {
  const { html } = data.data.markdownRemark
  const title = "Biometric Information Privacy Policy"

  return (
    <Layout>
      <Seo title={title} />
      <BannerWithText>
        <HeaderText color="white">{title}</HeaderText>
      </BannerWithText>

      <WysiwygContainer html={html} />
    </Layout>
  )
}

export default BiometricInformationPrivacyPolicy

export const query = graphql`
  {
    markdownRemark(
      frontmatter: { type: { eq: "biometric_information_privacy_policy" } }
    ) {
      html
    }
  }
`
